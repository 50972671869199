body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* styles.css */
body {
  margin: 0;
  overflow: hidden;
}

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0;
  perspective: 1000px; /* Perspective for 3D effect */
}

.image-wrapper {
  position: absolute;
  width: 200px; /* Width of your image */
  height: auto;
  transform-style: preserve-3d;
}

#draggable-image {
  width: 100%;
  height: auto;
  transform: rotateX(0deg) rotateY(0deg); /* Initial rotation */
  cursor: grab;
  transition: transform 0.2s ease-in-out; /* Smooth transition for rotation */
}

#draggable-image:active {
  cursor: grabbing;
}

.choose-city {
  position: absolute; /* Position it relative to its first positioned ancestor */
  top: 50px; /* Adjust based on where you want the text */
  left: 50%;
  transform: translateX(-50%); /* Center the text horizontally */
  font-size: 3rem; /* Make it big */
  font-weight: bold;
  color: rgb(51, 51, 51); /* Adjust text color */
  z-index: 10; /* Make sure it's on top of other content */
  text-align: center;
}

/*Tooltip*/
.tooltip {
  position: absolute;
  background: #fef9f0; /* Warm cream color */
  border: 1px solid #e2cbbf; /* Soft contrast border */
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  padding: 20px;
  max-width: 400px;
  z-index: 1000;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  color: #4a3b2a; /* Darker text for better readability */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltip p {
  margin: 0;
  padding-bottom: 16px;
  text-align: center;
}

.tooltip-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.tooltip-button {
  border: none;
  padding: 10px 20px;
  margin: 5px;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: bold;
}

.tooltip-button.accept {
  background-color: #d9e6d9; /* Soft green cream */
  color: #4a3b2a;
}

.tooltip-button.accept:hover {
  background-color: #b5d1b5;
}

.tooltip-button.close {
  background-color: #fbdada; /* Soft pink cream */
  color: #4a3b2a;
}

.tooltip-button.close:hover {
  background-color: #f8bcbc;
}


@media (max-width: 600px) {
  .choose-city {
    font-size: 2rem;
  }
}